const leaflets = {
    "demo": {
        "config": {
            "primary-color": "#ea7d00",
            "secondary-color": "#060606",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": require("assets/images/badil.jpeg"),
            "serverPath": "badil.volantinointerattivo.net/",
            "pdfPath": "",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Volantino Interattivo!",
            "shareFlyerURL": "https://badil.volantinointerattivo.net",
            "shareProductSentence": "Guarda questa fantastica offerta!",
            "ogTitleMeta": "Volantino Interattivo",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://badil.volantinointerattivo.net/media/demo_badil/images/share_facebook.png",
            "filterBrand": 0,
            "filterUnderprice": 1,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-13",
            "release_id": "1",
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 10,
            "client_id": 10,
            "signboard_id": 11,
            "has_pages": true,
            "type": "leaflet",
            "hide_grocery_list": true,
            "hide_searchbar": true,
            "line_through": false,
            "category_banner": "",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
        },
        "leaflet": {
            "name": "Brochure Badil",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/pages/indice_badil_brochure_eDQWkMH.png",
                "links": [{
                        "page": 2,
                        "blueprint": {
                            "top": 36.59489714744907,
                            "left": 16.12742923296647,
                            "width": 66.24904537351587,
                            "height": 10.640789949430696
                        }
                    }, {
                        "page": 3,
                        "blueprint": {
                            "top": 48.18645682421151,
                            "left": 16.204213918291188,
                            "width": 66.24904537351587,
                            "height": 10.640789949430696
                        }
                    }, {
                        "page": 4,
                        "blueprint": {
                            "top": 59.76635907539635,
                            "left": 16.85665384928386,
                            "width": 66.24904537351587,
                            "height": 10.640789949430696
                        }
                    }]
            },
            "categories": [],
            "pages": [{
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/pages/page_1_VbUHZ1l.jpg",
                    "products": []
                }, {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/pages/page_2_SD4dIOO.jpg",
                    "products": []
                }, {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/pages/page_3_wLPc8Lx.jpg",
                    "products": [{
                            "item_id": "cstm55018370",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 39.45789795392308,
                                "left": 7.478799925407899,
                                "width": 28.343911362296968,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_EaoZStA_oBOlN4S.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://badil.it/project-management/"
                                }]
                        }, {
                            "item_id": "cstm88307735",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 72.05386778230726,
                                "left": 6.874909483802635,
                                "width": 28.343911362296968,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_SVcwjet_fWK4lRz.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://badil.it/cloud-computing/"
                                }]
                        }, {
                            "item_id": "cstm90837752",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 39.51103855501041,
                                "left": 49.71369987114157,
                                "width": 17.02562442436037,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_1w7Ozog_eHihMeH.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://badil.it/web-application/"
                                }]
                        }, {
                            "item_id": "cstm71847224",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 60.34677279733367,
                                "left": 50.210083393500696,
                                "width": 17.02562442436037,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_Ne5ymwF_zFAttB5.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://badil.it/app-mobile/"
                                }]
                        }, {
                            "item_id": "cstm60116962",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 75.01492378500399,
                                "left": 49.52495667757394,
                                "width": 28.35084998927787,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_Y8VpLzO_4XKCeBy.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://badil.it/siti-web/"
                                }]
                        }]
                }, {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/pages/page_4_ga520YA.jpg",
                    "products": [{
                            "item_id": "cstm23395128",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 88.04192812939577,
                                "left": 69.79256069189377,
                                "width": 5.452698236016488,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_CD6zpw4_Au8YVTW.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.facebook.com/badil.it/"
                                }]
                        }, {
                            "item_id": "cstm49300390",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 87.86536971769058,
                                "left": 74.96299423049035,
                                "width": 5.452698236016488,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_Qnk6uc4_KiV6UJK.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.instagram.com/badilsrl/"
                                }]
                        }, {
                            "item_id": "cstm89861027",
                            "field1": "",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": null,
                            "subcategory": null,
                            "blueprint": {
                                "top": 87.93702094140207,
                                "left": 80.01552684235536,
                                "width": 5.452698236016488,
                                "height": 4.498230626730015
                            },
                            "images": [{
                                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/products/cropped_image_riNJ2DZ_Shy2Kfm.png",
                                    "cropped": true
                                }],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }, {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.linkedin.com/company/badil/"
                                }]
                        }]
                }, {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/TUBLdeg69H/pages/Endpage_brochure_badil_FS2B4Pc.png",
                    "products": []
                }]
        }

    }

}

const lefletSwitch = () => {
    return leaflets.demo;
}

export const serverResponse = lefletSwitch();