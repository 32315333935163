import React, { Component } from 'react';

import '../../assets/css/product.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {
    Row,
    Col,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Tooltip
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faPlusCircle,
    faMinusCircle,
    faBars,
    faShoppingBasket,
    faShoppingCart
} from '@fortawesome/free-solid-svg-icons';

import AddProd from '../../assets/images/icons/white_add_prod.png';
import Facebook from '../../assets/images/icons/facebook.svg';
import Whatsapp from '../../assets/images/icons/whatsapp.svg';

import PlusMarker from '../../components/leaflet/markers/PlusMarker';
import HatChefMarker from '../../components/leaflet/markers/HatChefMarker';
import InfoMarker from '../../components/leaflet/markers/InfoMarker';
import PlayMarker from '../../components/leaflet/markers/PlayMarker';
import SpecsMarker from '../../components/leaflet/markers/SpecsMarker';
import PromoMarker from '../../components/leaflet/markers/PromoMarker';
import WorldMarker from '../../components/leaflet/markers/WorldMarker';
import ShowPriceMarker from '../../components/leaflet/markers/ShowPriceMarker';
import { connect } from 'react-redux';
import actions from '../../redux/groceryList/action';
import { isIE, isEdge, isMobile } from 'react-device-detect';

import { Textfit } from 'react-textfit';

import { NotificationManager } from 'react-notifications';
import { serverResponse } from '../../util/fakeServer';

import { strings } from '../../util/data/strings';

import ReactGA from 'react-ga';
import { gaEventLabel } from '../../settings/gaConfig';
// import { borderRadius } from 'react-select/src/theme';

class Product extends Component {

    state = {
        itemCarousel: 0,
        varietySelected: (this.props.info.varieties ? this.props.info.varieties[0] : ""),
        saveTooltipOpen: false,
    }

    componentWillReceiveProps(props) {
        this.setState({
            quantity: (props.info.quantity_step ? props.info.quantity_step : 1),
            varietySelected: (props.info.varieties ? props.info.varieties[0] : "")
        })
    }

    componentDidMount() {
        this.setState({ quantity: (this.props.info.quantity_step ? this.props.info.quantity_step : 1) })
    }

    navMarkers = (newMarker) => {
        var element = document.getElementById(newMarker + "-" + this.props.info.item_id);
        element.click();
    }

    incrementQty = () => {
        this.setState({ quantity: this.state.quantity + (this.props.info.quantity_step ? this.props.info.quantity_step : 1) });
    }

    decrementQty = () => {
        if (this.props.info.quantity_step) {
            if (this.state.quantity > this.props.info.quantity_step) {
                this.setState({ quantity: this.state.quantity - (this.props.info.quantity_step) });
            }
        } else if (this.state.quantity > 1) {
            this.setState({ quantity: this.state.quantity - 1 });
        }
    }

    shareOnFacebook = () => {
        if (serverResponse.config.ga_active) {
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.share_product_facebook,
                label: String(this.props.info.item_id)
            });
        }
        // let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        // window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        let fb_share_url = "https://www.facebook.com/share.php?u=";
        let page_url = serverResponse.config.shareFlyerURL + "/share/product.php?c=" + this.props.info.item_id;
        window.open(fb_share_url + encodeURIComponent(page_url), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    shareOnWhatsapp = () => {
        if (serverResponse.config.ga_active) {
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.share_product_whatsapp,
                label: String(this.props.info.item_id)
            });
        }
        // let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        // //window.location = "http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc);
        // window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        let page_url = serverResponse.config.shareFlyerURL + "/share/product.php?c=" + this.props.info.item_id;
        window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(page_url), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    handleAddProduct = () => {
        let obj = Object.assign({}, this.props.info);
        obj.quantity = this.state.quantity;
        obj.done = false;
        obj.variety = this.state.varietySelected;
        this.props.addProduct(obj);
        NotificationManager.success('Controlla la tua lista della spesa', 'Prodotto aggiunto', 800);

        if (serverResponse.config.ga_active) {
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.add_to_grocery_list,
                label: String(this.props.info.item_id)
            });
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.open_product,
                label: String(this.props.info.item_id)
            });
        }
    }

    handleChangeVariety = (e) => {
        this.setState({
            varietySelected: e.target.value
        })
    }

    toggleSaveTooltip = () => {
        this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
    }

    render() {

        const {
            info
        } = this.props;

        const markers = info.markers.map((element, key) => {

            switch (element.type) {
                case "plus": {
                    return <PlusMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "info": {
                    return <InfoMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "world": {
                    return <WorldMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "hat-chef": {
                    return <HatChefMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "play": {
                    return <PlayMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "specs": {
                    return <SpecsMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "promo": {
                    return <PromoMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                default: return null
            }
        });

        let hidden_price_marker = [];
        if (this.props.info.hidden_price) {
            hidden_price_marker = this.props.info.markers.map((element, key) => {
                switch (element.type) {
                    case "play": {
                        return <ShowPriceMarker showButton={true} key={key + 10} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                    }
                    default: return null
                }
            });
        }

        let varieties = <Row className="varieties-container"></Row>;
        if (this.props.info.varieties) {
            if (this.props.info.varieties.length > 0) {
                varieties = <Row style={{ minHeight: "0px", marginBottom: '15px' }}>
                    <Col md="12">
                        {/* <Label for="variety">Scegli:</Label>*/}
                        <InputGroup>
                            {/*    <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>*/}
                            <InputGroupAddon addonType="prepend"></InputGroupAddon>
                            <Input type="select" name="select" id="variety" value={this.state.varietySelected} onChange={this.handleChangeVariety}>
                                {
                                    this.props.info.varieties.map((element, key) => {
                                        return <option key={key} value={element}>{element}</option>
                                    })
                                }
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>;
            }
        }

        let brandImg = "";
        if (serverResponse.config.brandImage !== "" && this.props.info.pam === true) {
            brandImg = <img style={{ width: "30%" }} src={process.env.PUBLIC_URL + serverResponse.config.brandImage} alt="img" />;
        }

        let cardImg = "";
        if (serverResponse.config.underPriceImage !== "" && this.props.info.underpriced_product === true) {
            cardImg = <img style={{ width: "50%", marginTop: "10px" }} src={process.env.PUBLIC_URL + serverResponse.config.underPriceImage} alt="img" />;
        }

        let h6Class = '';
        let isLineThrough = false;
        if (Boolean(serverResponse.config.line_through) === true) {
            h6Class = 'barrato';
            isLineThrough = true;
        }

        return (
            <div className="ptb-15">
                <div className="roe-card-style">
                    <div className="roe-card-header custom-card-header" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Row>
                            <Col md="12">
                                <div className="markers-container markers-container-no-margin">
                                    {markers}
                                    <img src={Facebook} className="share-icon" style={{ marginLeft: "auto" }} alt="facebook" onClick={() => this.shareOnFacebook()} />
                                    <img src={Whatsapp} className="share-icon" alt="whatsapp" onClick={() => this.shareOnWhatsapp()} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="roe-card-body">
                        <Row>
                            <Col md="12" className="container-fields">
                                <Textfit mode="multi" style={{ height: '26px' }}>
                                    <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>{info.field1}</span>
                                </Textfit>
                                <Textfit mode="multi" min={10} max={15} style={{ minHeight: "70px" }}>
                                    <div style={{ textTransform: "uppercase" }}>{info.field2}</div>
                                    <div style={{ textTransform: "lowercase" }}>{info.field3}</div>
                                    {!isLineThrough &&
                                        <div className={h6Class} style={{ textTransform: "lowercase" }}>{info.field4}</div>
                                    }
                                </Textfit>
                                {brandImg}
                                {cardImg}
                            </Col>
                            <Col md={{ size: 12, offset: 0 }}>
                                <div className="carousel-container text-center" style={{ position: 'relative' }}>
                                    {info.focus && <div className="notDisponibile"></div>}
                                    {!isMobile && info.images.length > 1 &&
                                        <Carousel
                                            swipeable={true}
                                            emulateTouch={true}
                                            infiniteLoop={true}
                                            showStatus={false}
                                            dynamicHeight={true}
                                            showThumbs={false}
                                            showIndicators={false}
                                            selectedItem={this.state.itemCarousel}
                                            onChange={(current) => this.setState({ itemCarousel: current })}
                                        >
                                            {
                                                info.images.map((image, key) =>
                                                    <img key={key} src={process.env.PUBLIC_URL + image.image_file} alt="img" />
                                                )
                                            }

                                        </Carousel>
                                    }
                                    {isMobile &&
                                        <img src={process.env.PUBLIC_URL + info.images[0].image_file} alt="" style={{ maxHeight: '218px' }} />
                                    }
                                    {!isMobile && info.images.length < 2 &&
                                        <img src={process.env.PUBLIC_URL + info.images[0].image_file} alt="" style={{ maxHeight: '218px' }} />
                                    }
                                    < div className="offertaBadge">
                                        {info.underpriced_product && <p style={{ display: "inline-block", padding: "5px", backgroundColor: "red", borderRadius: "4px", fontWeight: "bold", color: "#ffffff" }}>OFFERTA</p>}
                                    </div>
                                    {info.three_for_two &&
                                        <div className="onlyShopping">DISPONIBILE SOLO PER IL RITIRO AL PUNTO VENDITA</div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: "space-between", marginTop: '10px' }}>
                            {/*  <Col xl="12" sm="12" lg="12" md="12" xs="12">
                                <div style={{ height: "34px", display: "inlineBlock" }}>
                                    {info.underpriced_product &&
                                        <p style={{ display: "inline-block", padding: "5px", backgroundColor: "red", borderRadius: "4px", fontWeight: "bold", color: "#ffffff" }}>
                                            IN OFFERTA
                                        </p>
                                    }
                                </div>
                                </Col> */}
                            <Col className={(info.focus || info.one_and_one_gratis) ? 'col-12' : 'col-6'} md={(info.focus || info.one_and_one_gratis)? 12 : 6} lg={(info.focus || info.one_and_one_gratis) ? 12 : 8} style={{ minHeight: '140px' }}>
                                <div className="product-price">
                                    {isLineThrough && info.field4 != '' && 
                                        <div className={h6Class} style={{ textTransform: "lowercase", fontSize: '14px' }}>{info.field4}</div>
                                    }
                                    {isLineThrough && info.field4 == '' && 
                                        <div style={{ textTransform: "lowercase", fontSize: '14px' }}>&nbsp;</div>
                                    }
                                    <Textfit mode="multi" max={100} style={{ height: "40px", marginBottom: "15px", width: '100%' }}>
                                        {info.hidden_price &&
                                            <span style={{ fontWeight: 'bold' }}>{hidden_price_marker}</span>
                                        }
                                        {!info.hidden_price &&
                                            <span style={{ fontWeight: 'bold' }}>{info.price_label}</span>
                                        }
                                    </Textfit>
                                </div>
                                <div>
                                    {varieties}
                                </div>
                                {!info.focus && !info.one_and_one_gratis &&
                                    <div className="container-add-to-list container-add-to-list-color" style={{ visibility: (this.props.info.hidden_price ? "hidden" : "") }}>
                                        <FontAwesomeIcon className="change-product-quantity-icon" icon={faMinusCircle} onClick={() => this.decrementQty()} />
                                        <h6>{this.state.quantity} <span style={{ fontWeight: "bold" }}>{info.grocery_label}</span></h6>
                                        <FontAwesomeIcon className="change-product-quantity-icon" icon={faPlusCircle} onClick={() => this.incrementQty()} />
                                    </div>
                                }
                            </Col>
                            {!info.focus && !info.one_and_one_gratis &&
                                <Col className="col-6 align-self-center" md={{ size: 4, offset: 2 }} lg={{ size: 4, offset: 0 }}>
                                    <div className="" style={{ justifyContent: "flex-end", textAlign: "center" }}>
                                        <Button className="button-send-to-cart" id={"btnSave_" + info.item_id} color="primary" onClick={this.handleAddProduct}>
                                            <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: '35px' }} />
                                            {/*<img src={AddProd} style={{ color: "#00ff00", width: "30px", cursor: "pointer" }} alt="img" />*/}
                                        </Button>
                                        {!isMobile &&
                                            <Tooltip placement="top" isOpen={this.state.saveTooltipOpen} target={"btnSave_" + info.item_id} toggle={this.toggleSaveTooltip}>
                                                {strings.saveBtnTooltip}
                                            </Tooltip>
                                        }
                                    </div>

                                </Col>
                            }
                            {/*<Col xl="12" sm="12" lg="12" md="12" xs="12">
                                <div className="product-price custom-product-price-varieties">
                                    {info.hidden_price &&
                                        <p style={{ textAlign: "center" }}>
                                            {hidden_price_marker}
                                        </p>
                                    }
                                    {!info.hidden_price &&
                                        <p>{info.price_label}</p>
                                    }
                                </div>
                                </Col> 
                            <Col xl="12" sm="12" lg="12" md="12" xs="12">
                                <div>
                                    {varieties}
                                </div>
                            </Col>*/}
                        </Row>
                        {/*<Row>
                            <Col md="12">
                                {varieties}
                            </Col>
                        </Row>*/}
                        <Row style={{ visibility: (this.props.info.hidden_price ? "hidden" : "") }}>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Col md="6" className="product-price">
                                    {/*<div className="container-add-to-list">
                                        <FontAwesomeIcon className="change-product-quantity-icon" icon={faMinusCircle} onClick={() => this.decrementQty()} />
                                        <h6>{this.state.quantity}</h6>
                                        <h6 style={{ fontWeight: "bold" }}>{info.grocery_label}</h6>
                                        <FontAwesomeIcon className="change-product-quantity-icon" icon={faPlusCircle} onClick={() => this.incrementQty()} />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="container-add-to-list" style={{ justifyContent: "flex-end" }}>
                                        <Button id={"btnSave_" + info.item_id} color="primary" onClick={this.handleAddProduct}>
                                            <img src={AddProd} style={{ color: "#00ff00", width: "30px", cursor: "pointer" }} alt="img" />
                                        </Button>

                                        <Tooltip placement="top" isOpen={this.state.saveTooltipOpen} target={"btnSave_" + info.item_id} toggle={this.toggleSaveTooltip}>
                                            {strings.saveBtnTooltip}
                                        </Tooltip>
                                    </div>*/}
                                </Col>
                            </div>
                        </Row>
                    </div>
                </div>

            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addProduct: (newProduct) => {
            dispatch(actions.addProduct(newProduct));
        },
    }
}

export default connect(null, mapDispatchToProps)(Product);