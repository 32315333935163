import { serverResponse } from "../util/fakeServer";

export const AppName = serverResponse.config.type === 'leaflet' ? "Volantino Interattivo" : "Menù Interattivo";
export const drawerWidth = "260px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";



export const iconDemo = serverResponse.config.type === 'leaflet' ? require("assets/images/logo.png") : require("assets/images/menu_logo.png");
export const iconAppName = serverResponse.config.type === 'leaflet' ? require("assets/images/appname.png") : require("assets/images/menu.png");
export const iconAppNameNegative = serverResponse.config.type === 'leaflet' ? require("assets/images/appnameNegative.png") : require("assets/images/menuNegative.png");
export const euroesse_clientIcon = require("assets/images/euroesse_clientLogo.png");
export const dok_clientIcon = require("assets/images/dok_clientLogo.png");
export const ForgotIcon = require("assets/images/forgotpassword.svg");
export const ProfileLockScreen = require("assets/images/profile.jpg");
export const feed1 = require("assets/images/feed1.jpg");
export const feed2 = require("assets/images/feed2.jpg");
export const friend1 = require("assets/images/friend1.jpg");
export const friend2 = require("assets/images/friend2.jpg");
export const friend3 = require("assets/images/friend3.jpg");
export const friend4 = require("assets/images/friend4.jpg");
export const friend5 = require("assets/images/friend5.jpg");
export const friend6 = require("assets/images/friend6.jpg");
export const media1 = require("assets/images/media1.jpeg");
export const media2 = require("assets/images/media2.jpeg");
export const media3 = require("assets/images/media3.jpeg");
export const media4 = require("assets/images/media4.jpeg");
export const media5 = require("assets/images/media5.jpeg");
export const media6 = require("assets/images/media6.jpeg");
export const media7 = require("assets/images/media7.jpeg");
export const media8 = require("assets/images/media8.jpeg");
export const media9 = require("assets/images/media9.jpeg");
export const sidebar1 = require("assets/images/sidebar1.jpg");
export const sidebar2 = require("assets/images/sidebar2.jpg");
export const sidebar3 = require("assets/images/sidebar3.jpg");
export const sidebar4 = require("assets/images/sidebar4.jpg");
export const sidebar5 = require("assets/images/sidebar5.jpg");
export const sidebar6 = require("assets/images/sidebar6.jpg");
export const sidebar7 = require("assets/images/sidebar7.jpg");
export const sidebar8 = require("assets/images/sidebar8.jpg");


//Custom color
export const primaryColor = "#e48a00";
export const secondaryColor = "#dc2311";
export const tertiaryColor = "#ffdd00";

//LocalStorage variables name
export const idCampaignName = "idcmpgn";
export const myGroceryListName = "mygrcrlst";
